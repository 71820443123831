import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import BlogsPreview from '../../components/BlogCardComponents/BlogsPreview';
import WNButton from '../../components/FormComponents/WNButton';
import WNInput from '../../components/FormComponents/WNInput';
import WNInputErrorMessage from '../../components/FormComponents/WNInputErrorMessage';
import Backdrop from '../../hoc/Backdrop';
import Heading from '../../hoc/Heading';
import SuccessModal from '../../hoc/SuccessModal';
import { resetUserState } from '../../store/reducers/userReducer';

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [uidToken, setUidToken] = useState({ uid: '', token: '' });
  const [passwordInput, setPasswordInput] = useState('');
  const [confirmPasswordInput, setConfirmPasswordInput] = useState('');
  const [passwordInputError, setPasswordInputError] = useState<string>('');
  const [confirmPasswordInputError, setConfirmPasswordInputError] =
    useState<string>('');
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    const pathname = window.location.pathname;
    let pathArray = pathname.split('/');
    if (pathArray.length >= 4) {
      setUidToken({ uid: pathArray[2], token: pathArray[3] });
    }
  }, []);

  const handleResetPassword = async (event: any) => {
    event.preventDefault();
    const isPasswordValid = validatePasswordInput();
    const isConfirmPasswordValid = validateConfirmPasswordInput();

    if (isPasswordValid && isConfirmPasswordValid) {
      axios
        .post('/api/auth/users/reset_password_confirm/', {
          uid: uidToken.uid,
          token: uidToken.token,
          new_password: passwordInput,
        })
        .then(() => setShowSuccessModal(true))
        .catch(e => {});
    }
  };

  useEffect(() => {
    const passwordInputIsValid = passwordInput !== '';
    const confirmPasswordInputIsValid = confirmPasswordInput !== '';
    if (passwordInputIsValid && confirmPasswordInputIsValid) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [passwordInput, confirmPasswordInput]);

  const validatePasswordInput = () => {
    if (passwordInput.length < 8) {
      setPasswordInputError('password must be at least 8 characters long');
    } else {
      setPasswordInputError('');
      return true;
    }
  };

  const validateConfirmPasswordInput = () => {
    if (passwordInput === '') {
      setConfirmPasswordInputError('please confirm password');
      return false;
    } else if (passwordInput !== confirmPasswordInput) {
      setConfirmPasswordInputError('passwords do not match');
      return false;
    } else {
      setConfirmPasswordInputError('');
      return true;
    }
  };

  const handlePasswordInputChange = (e: any) => {
    if (passwordInputError) setPasswordInputError('');
    setPasswordInput(e.target.value);
  };

  const handleConfirmPasswordInputChange = (e: any) => {
    if (confirmPasswordInputError) setConfirmPasswordInputError('');
    setConfirmPasswordInput(e.target.value);
  };

  const handleContinue = () => {
    dispatch(resetUserState());
    navigate('/login-or-signup');
  };

  return (
    <div className="login-page">
      <div className="login-signup-component">
        <Heading headingText="Reset your password" />
        <form name="login-form" className="login-signup-form">
          <WNInput
            id={'password-input'}
            label={'Password'}
            placeholder={'Password'}
            value={passwordInput}
            optionalClass={passwordInputError && 'input-error'}
            onChange={handlePasswordInputChange}
          />
          <WNInputErrorMessage errorMessage={passwordInputError} />
          <WNInput
            id={'confirm-password-input'}
            label={'Confirm Password'}
            placeholder={'Confirm Password'}
            value={confirmPasswordInput}
            optionalClass={confirmPasswordInputError && 'input-error'}
            onChange={handleConfirmPasswordInputChange}
          />
          <WNInputErrorMessage errorMessage={confirmPasswordInputError} />
          <WNButton
            buttonText="Reset Password"
            onClick={handleResetPassword}
            disabled={isButtonDisabled}
          />
          <SuccessModal
            show={showSuccessModal}
            successText="Password successfully changed."
            buttonOneText="Continue"
            handleFirstButtonClick={handleContinue}
          />
          <Backdrop show={showSuccessModal} />
        </form>
      </div>
      <BlogsPreview />
    </div>
  );
};

export default ResetPasswordPage;
