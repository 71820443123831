import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import BlogsPreview from '../../components/BlogCardComponents/BlogsPreview';
import WNButton from '../../components/FormComponents/WNButton';
import WNInput from '../../components/FormComponents/WNInput';
import WNInputErrorMessage from '../../components/FormComponents/WNInputErrorMessage';
import Backdrop from '../../hoc/Backdrop';
import Heading from '../../hoc/Heading';
import SuccessModal from '../../hoc/SuccessModal';
import { resetUserState } from '../../store/reducers/userReducer';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emailInput, setEmailInput] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const handleEmailInputOnChange = (event: any) => {
    setEmailInput(event.target.value);
  };

  const handleForgetEmail = async (event: any) => {
    event.preventDefault();
    try {
      await axios.post('/api/auth/users/reset_password/', {
        email: emailInput,
      });
    } catch (e) {
    } finally {
      setShowSuccessModal(true);
      resetUserState();
    }
  };

  const handleContinue = () => {
    dispatch(resetUserState());
    navigate('/login-or-signup');
  };

  return (
    <div className="login-page">
      <div className="login-signup-component">
        <Heading headingText="Forgot your password?" />
        <p className="body1">
          Enter your email address and we'll send you a link to reset your
          password.
        </p>
        <form name="login-form" className="login-signup-form">
          <WNInput
            id={'login-email-input'}
            label={'Your Email'}
            placeholder={''}
            value={emailInput}
            onChange={handleEmailInputOnChange}
          />
          <WNInputErrorMessage errorMessage={''} />
          <WNButton
            buttonText="Send password email"
            onClick={handleForgetEmail}
          />
          <SuccessModal
            show={showSuccessModal}
            successText={`An email has been sent to ${emailInput} with further instructions.`}
            buttonOneText="Continue"
            handleFirstButtonClick={handleContinue}
          />
          <Backdrop show={showSuccessModal} />
        </form>
      </div>
      <BlogsPreview />
    </div>
  );
};

export default ForgotPasswordPage;
